import * as React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Layout } from "../layouts/default"
import { BlogCard } from "../components/Card"
import { Container } from "../components/Container"
import { Headline } from "../components/Headline"
import { SectionHeader } from "../components/SectionHeader"
import { HeadlineButton } from "../components/HeadlineButton"
import { cleanSrcSet } from "../lib/utils"
import { listing } from "./blog.module.css"
import {
  marginBottomLarge,
  flex,
  paddingLeft,
  paddingRight,
  fontBold,
  relative,
} from "../components/utils.module.css"
import { masthead, mastheadImage } from "../components/masthead.module.css"

export default function Blog({ data }) {
  const posts = data.posts.nodes

  const cards = []
  for (let i = 0; i < 3; i++) {
    if (posts[i]) {
      cards.push(
        <BlogCard
          key={posts[i].fields.slug}
          slug={posts[i].fields.slug}
          teaserImage={
            posts[i].frontmatter.heroimage.desktop.childImageSharp.fixed
          }
          title={posts[i].frontmatter.title}
          category={posts[i].frontmatter.category}
          excerpt={posts[i].excerpt}
        />
      )
    } else {
      cards.push(<div />)
    }
  }

  const siteMetadata = data.allSite.nodes[0].siteMetadata

  return (
    <Layout siteMetadata={siteMetadata}>
      <div className={relative}>
        <Image
          fluid={cleanSrcSet(data.masthead.nodes[0].childImageSharp.fluid)}
          className={mastheadImage}
        />
        <Container
          className={relative}
          style={{
            maxHeight:
              data.masthead.nodes[0].childImageSharp.fluid.presentationHeight,
            height: "60vh",
          }}
        >
          <div
            className={`${flex} ${masthead} ${fontBold} ${paddingLeft} ${paddingRight}`}
          >
            <Headline className={marginBottomLarge}>
              An up-to-date team of highly skilled developers, we are.
            </Headline>

            <HeadlineButton href="#blog-posts">Start reading</HeadlineButton>
          </div>
        </Container>
      </div>

      <Container as="section" style={{ marginTop: 150 }}>
        <SectionHeader title="Blogs" subtitle="Up-to-date on technology" />
        <div id="blog-posts" className={listing}>
          {cards}
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query BlogPage {
    ...MetaDataQuery
    masthead: allFile(filter: { name: { eq: "homepage-masthead-desktop" } }) {
      nodes {
        childImageSharp {
          fluid(maxHeight: 775, srcSetBreakpoints: [450, 615, 775]) {
            ...GatsbyImageSharpFluid_withWebp
            presentationHeight
          }
        }
      }
    }
    posts: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          category
          title
          heroimage {
            desktop {
              childImageSharp {
                fixed(width: 373, height: 453, fit: COVER, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        excerpt
      }
    }
  }
`
